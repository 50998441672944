<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-color">
        <router-link to="/" class="navbar-brand m-0 mr-4 p-0 h1">
            <img src="../../assets/logo.png" class="mr-2 logo">
            <span>Foxpay</span>
        </router-link>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <!--Development Menu - To access pages faster - Will not be shown on production-->
            <ul class="navbar-nav ml-auto" v-if="prodEnvironment === false">
                <router-link to="/success" class="nav-link">Success</router-link>
                <router-link to="/error" class="nav-link">Error</router-link>
                <router-link to="/notFound" class="nav-link">Not Found</router-link>
            </ul>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'homeHeader',
        data() {
            return {
                prodEnvironment : process.env.NODE_ENV === 'production'
            }
        },
    }
</script>

<style scoped>
    .logo {
        width: 40px;
    }
</style>